<template>
  <button
    class="news-wrapper"
    title="clique para ver as novidades"
    type="button"
    @click="$emit('toggleNews')"
  >
    <span class="icon-wrapper">
      <span class="mdn-Icon-alto-falante mdn-Icon--md icon" aria-label="alto-falante"></span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'McrNewsBtn'
}
</script>

<style lang="scss" scoped>
.news-wrapper {
  width: 60px;
  height: 60px;
  padding: 0;
  padding-right: 11px;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: var(--theme-color-brand-primary-medium);
  text-align: right;
  cursor: pointer;
  position: fixed;
  top: 35%;
  left: 0;
  z-index: 10;
  transition: width 0.2s;

  .icon-wrapper {
    display: block;
    width: 34px;
    height: 34px;
    animation-name: shake;
    animation-duration: 7s;
    animation-timing-function: linear;
    animation-delay: 3s;
    animation-iteration-count: 3;

    .icon {
      width: 34px;
      height: 34px;
      line-height: 34px;
      color: var(--theme-color-neutral-lightest);
    }

    span.icon:before {
      font-size: 34px;
    }
  }
}

.news-wrapper:hover {
  width: 76px;
}

@keyframes shake {

  0%,
  94% {
    transform: rotate(0);
  }

  95% {
    transform: rotate(-10deg);
  }

  96% {
    transform: rotate(10deg);
  }

  97% {
    transform: rotate(-10deg);
  }

  98% {
    transform: rotate(10deg);
  }

  99% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(10deg);
  }
}
</style>
