<template>
  <div>
    <McrNewsModal
      v-if="isShowNews"
      @toggleNews="toggleNews()"
    />
    <McrNewsBtn
      v-if="isShowNewsBtn"
      @toggleNews="
        toggleNews();
        pushTag();
      "
    />
  </div>
</template>

<script>
import McrNewsBtn from "./parts/McrNewsBtn.vue";
import McrNewsModal from "./parts/McrNewsModal.vue";

const isNewsViewed = "isNewsViewed";

export default {
  components: {
    McrNewsBtn,
    McrNewsModal,
  },

  data: () => {
    return {
      // ativa ou desativa o componente
      isActive: true,
      // "YYYY/MM/DD"
      validUntil: new Date("2024/12/26"),
      isShowNews: false,
      isShowNewsBtn: false,
    }
  },

  methods: {
    pushTag,
    toggleNews,
  },

  mounted,
  name: "McrNews",
}

function mounted() {

  if (!this.isActive) {
    return;
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if ((today > this.validUntil) || localStorage.getItem(isNewsViewed)) {
    return;
  }

  this.isShowNewsBtn = true;
}

function toggleNews() {
  this.isShowNews = !this.isShowNews;

  if (!this.isShowNews) {
    this.isShowNewsBtn = false;
    window.localStorage.setItem(isNewsViewed, true);
  }
}

function pushTag() {
  const tag = {
    custom_section: "minha_claro_res:home",
    custom_title: "novidades_abrir",
    event: "clique",
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(tag);
}

</script>

<style lang="scss" scoped>
</style>
