<template>
  <div v-if="$root.pageState.READY && user && user.selectedContract && chatbotURL">
    <button
      class="chat-control"
      :class="{ 'chat-control-close': isChatOpen }"
      id="chat-control"
      :title="`clique para ${isChatOpen ? 'fechar' : 'abrir'} o chat`" type="button"
      @click="toggleChat()"
    >
      <svg v-if="!isChatOpen" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
          fill="#DA291C" />
        <path
          d="M29.7056 11.0001C25.7156 11.0001 21.5956 11.6501 18.7656 13.3601C14.1756 16.1901 12.8356 21.7001 13.0156 26.8001C13.1556 31.2001 14.3156 35.9301 17.4656 39.1801C20.4356 42.2901 26.5556 43.6801 30.7656 43.6801C30.9556 43.6801 31.1356 43.8201 31.1356 44.0501V48.5001C31.1356 48.9201 31.4556 49.2901 31.9256 49.2901H32.0156C32.2056 49.2901 32.3356 49.2001 32.4356 49.1001C34.5256 47.2901 36.6556 45.4401 38.6956 43.5801C40.6456 41.7701 42.8656 39.9201 44.3556 37.7401C47.5556 33.1501 48.0656 26.7101 46.9956 21.3301C45.9756 16.1801 42.3156 12.8001 37.2156 11.7301C35.0356 11.2701 32.3956 11.0001 29.7056 11.0001Z"
          fill="white" />
      </svg>

      <svg v-if="isChatOpen" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="60" height="60" rx="30" fill="#DA291C" />
        <path
          d="M31.6399 30.0016L37.8138 23.8311C38.2689 23.3761 38.2689 22.6413 37.8138 22.1864C37.3586 21.7314 36.6234 21.7314 36.1682 22.1864L29.9943 28.3569L23.8205 22.1747C23.3653 21.7198 22.63 21.7198 22.1749 22.1747C21.7197 22.6296 21.7197 23.3645 22.1749 23.8194L28.3487 30.0016L22.1749 36.1722C21.7197 36.6271 21.7197 37.362 22.1749 37.8169C22.3966 38.0502 22.6884 38.1668 22.9918 38.1668C23.2953 38.1668 23.587 38.0502 23.8205 37.8286L29.9943 31.6463L36.1682 37.8169C36.4016 38.0502 36.6934 38.1668 36.9968 38.1668C37.3003 38.1668 37.592 38.0502 37.8255 37.8286C38.2806 37.3736 38.2806 36.6388 37.8255 36.1838L31.6399 30.0016Z"
          fill="white" />
      </svg>
    </button>

    <div data-testid="chatbot-claro-wrapper" id="chat-wrapper" v-if="isChatOpen">
      <iframe data-testid="chatbot-claro" :src="chatbotURL" title="chat minha claro residencial">
      </iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["user"]),
  },

  data() {
    return {
      chatbotURL: "",
      isChatOpen: false,
    }
  },

  methods: {
    makeChatUrl,
    toggleChat,
  },

  mounted,
  name: "McrChat",
}

function mounted() {
  this.makeChatUrl();
}

function toggleChat() {
  const tag = {
    custom_section: "minha_claro_res:home",
    custom_title: "",
    event: "clique",
  }

  window.dataLayer = window.dataLayer || [];
  this.isChatOpen = !this.isChatOpen;

  if (this.isChatOpen) {
    tag.custom_title = "chatbot_abrir";
  } else {
    tag.custom_title = "chatbot_fechar";
  }

  window.dataLayer.push(tag);
}

function makeChatUrl() {
  try {
    const domain = process.env.VUE_APP_APPBOT_CLARO_DOMAIN;
    const key = process.env.VUE_APP_APPBOT_CLARO_KEY;
    const clientToken = window.encodeURIComponent(window.McrProps.IDM_ACCESS_TOKEN);
    const resourceId = `${this.user.selectedContract.operatorCode}${this.user.selectedContract.contractNumber}`;

    if (!domain || !key || !clientToken || !resourceId) {
      throw new Error("url do chatbot está inválida.")
    }

    this.chatbotURL = `${domain}?key=${key}&clientToken=${clientToken}&resourceId=${resourceId}`;
  } catch (error) {
    const docUrl = document.URL;
    this.chatbotURL = '';

    if (docUrl.includes("localhost") || docUrl.includes(process.env.VUE_APP_HOME_URL)) {
      console.log(error)
    }
  }
}

</script>

<style lang="scss" scoped>
$chat-control-size: 60px;

.chat-control {
  border-radius: var(--border-radius-circle);
  overflow: hidden;
  position: fixed;
  right: var(--spacing-inline-xs);
  bottom: var(--spacing-stack-xl);
  z-index: 10;
  border: var(--border-width-none);
  padding: 0;
  background: none;
  overflow: hidden;
  cursor: pointer;

  svg {
    width: $chat-control-size;
    height: $chat-control-size;
  }
}

.chat-control-close {
  bottom: unset;
  top: 0;
  z-index: 100;

  svg {
    width: 50px;
    height: 50px;
  }

  @media (min-width: 768px) {
    top: unset;
    bottom: 64px;

    svg {
      width: $chat-control-size;
      height: $chat-control-size;
    }
  }
}

#chat-wrapper {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-xxs);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 90;
  background-color: var(--theme-color-neutral-lightest);
  box-shadow: 0px var(--shadow-offset-y-level-3) var(--shadow-blur-radius-level-4) 0px #1F1D1D29;

  iframe {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    $rightSpace: 48px;
    $elementWidth: 400px;

    width: $elementWidth;
    height: 70%;
    max-height: 612px;
    top: unset;
    bottom: 128px;
    left: calc(100% - (#{$elementWidth} / 2) - #{$rightSpace});
  }
}
</style>