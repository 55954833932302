<template>
  <div>
    <mcr-autodiagnostic-wrapper />
    <mcr-news v-if="$root.pageState.READY" />
    <mcr-chat />
    <micro-frontend pageId="mfe-dashboard" />
    <micro-frontend pageId="mfe-campaigns" />
  </div>
</template>

<script>
import McrAutodiagnosticWrapper from "../../components/McrAutodiagnostic.vue";
import McrNews from "../../components/news/McrNews.vue";
import McrChat from "../../components/McrChat.vue";
import MicroFrontend from "../MicroFrontend.vue";

export default {
  name: "dashboard",
  components: {
    McrAutodiagnosticWrapper,
    McrChat,
    McrNews,
    MicroFrontend
  }
};
</script>
