<template>
  <div class="mdn-Modal mdn-Modal--sm mdn-isOpen">
    <div class="mdn-Modal-content">
      <div class="mdn-Modal-header">
        <h4 class="mdn-Heading mdn-Heading--xs">
          Tem novidade para você!
        </h4>
        <button class="mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary"
          aria-label="fechar modal" @click="$emit('toggleNews')">
          <i aria-hidden="true" class="mdn-Icon-fechar mdn-Icon--sm"></i>
        </button>
      </div>
      <div class="mdn-Divider mdn-Divider--default"></div>
      <div class="mdn-Modal-body noMarginTop">
        <h5 class="mdn-Text mdn-Text--body margin-bottom-md">Agora você pode fazer recargas de uma forma mais prática</h5>
        <ul class="news-list">
          <li>
            <p class="mdn-Text mdn-Text--body">1. Só clicar em "Atendimento" no menu</p>
          </li>
          <li>
            <p class="mdn-Text mdn-Text--body">2. Depois em "Serviços"</p>
          </li>
          <li>
            <p class="mdn-Text mdn-Text--body">3. E, logo após, em "Recarga Claro"</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "McrNewsModal"
}
</script>

<style lang="scss" scoped>
.news-list li {
  margin-bottom: var(--spacing-stack-sm);
}

.margin-bottom-md {
  margin-bottom: var(--spacing-stack-md);
}
</style>